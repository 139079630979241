<template>
  <div class="pane_body">
    <v-card flat color="rgb(255 255 245)">
      <v-toolbar flat dense class="breads">
        <v-breadcrumbs :items="breadcrumb">
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
          <template v-slot:item="{ item }">
            <v-breadcrumbs-item :to="item.to" :disabled="item.disabled">
              {{ item.text.toUpperCase() }}
            </v-breadcrumbs-item>
          </template>
        </v-breadcrumbs>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              x-small
              text
              v-on="on"
              color="success"
              @click.stop="newDialog = true"
            >
              new
            </v-btn>
          </template>
          <span>Create new Document Type</span>
        </v-tooltip>
        <PagesNew
          :newDialog="newDialog"
          @closeNew="closeNew"
          @submitNew="submitNew"
        />

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon text v-on="on" color="info" @click="refresh">
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </template>
          <span>Refresh</span>
        </v-tooltip>
      </v-toolbar>
    </v-card>

    <v-container>
      <v-card color="transparent" flat>
        <v-card flat>
          <v-data-table
            :loading="loading"
            loader-height="2"
            :headers="headers"
            :sort-by="['id']"
            :sort-desc="[false]"
            :items="pagesList"
            :items-per-page="8"
            :footer-props="{ itemsPerPageOptions: [8, 16, 24] }"
          >
            <template v-slot:item.name="{ item }">
              {{ item.name | capitalize }}
            </template>

            <template v-slot:item.actions="{ item }">
              <!-- <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    color="primary"
                    icon
                    v-on="on"
                    @click.stop="$set(viewDialog, item.id, true)"
                  >
                    <v-icon>mdi-eye-outline</v-icon>
                  </v-btn>
                </template>
                <span>View</span>
              </v-tooltip>
              <PageView
                v-if="viewDialog[item.id]"
                :myId="item.id"
                :name="item.name"
                :viewDialog="viewDialog[item.id]"
                @closeView="closeView"
              /> -->

              <!-- <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    icon
                    text
                    v-on="on"
                    color="success"
                    @click.stop="$set(editDialog, item.id, true)"
                  >
                    <v-icon>mdi-circle-edit-outline</v-icon>
                  </v-btn>
                </template>
                <span>Edit Page</span>
              </v-tooltip>
              <PageEdit
                v-if="editDialog[item.id]"
                :myId="item.id"
                :name="item.name"
                :editDialog="editDialog[item.id]"
                @closeEdit="closeEdit"
                @submitEdit="submitEdit"
              /> -->

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    icon
                    text
                    v-on="on"
                    color="red"
                    @click.stop="$set(removeDialog, item.id, true)"
                  >
                    <v-icon>mdi-delete-outline</v-icon>
                  </v-btn>
                </template>
                <span>Delete</span>
              </v-tooltip>
              <PagesDelete
                v-if="removeDialog[item.id]"
                :myId="item.id"
                :name="item.name"
                :removeDialog="removeDialog[item.id]"
                @closeRemove="closeRemove"
                @submitRemove="submitRemove"
              />

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    color="primary"
                    x-small
                    text
                    v-on="on"
                    style="margin-left: 15px"
                    :to="{
                      name: 'admin.cms.pages',
                      params: { myId: item.id, name: item.name },
                    }"
                  >
                    details
                  </v-btn>
                </template>
                <span>To details</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-card>
    </v-container>
    <v-snackbar
      :color="snack.color"
      v-model="snack.bar"
      :multi-line="snack.multiLine"
    >
      {{ snack.text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snack.bar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Restful from "@/services/RestFul";
import PagesNew from "../../components/cms/pages/newone.vue";
// import PageView from "../../components/cms/pages/view.vue";
// import PageEdit from "../../components/cms/pages/edit.vue";
import PagesDelete from "../../components/cms/pages/remove.vue";
export default {
  name: "Pages",
  components: {
    PagesNew,
    //  PageView, PageEdit,
    PagesDelete,
  },
  data: () => ({
    newDialog: false,
    viewDialog: {},
    editDialog: {},
    removeDialog: {},
    breadcrumb: [
      {
        text: "Dashboard",
        disabled: false,
        to: { name: "admin.dash" },
      },
      {
        text: "Web Pages",
        to: { name: "admin.cms" },
      },
    ],
    snack: {
      color: "info",
      multiLine: true,
      bar: false,
      text: "Processing ... ",
    },
    loading: false,
    pagesList: [],
    headers: [
      { text: "name", value: "name" },
      { text: "title", value: "title" },
      { text: "description", value: "description" },
      { text: "actions", value: "actions", align: "center" },
    ],
  }),
  created() {
    this.list();
  },
  methods: {
    refresh() {
      this.list();
    },
    list() {
      this.loading = true;
      let self = this;
      Restful.cms.pages
        .list()
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            this.pagesList = response.data;
          }
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
          console.log(error.response.data.message);
          self.loading = false;
        });
    },
    closeNew(p) {
      this.newDialog = p.state;
    },
    submitNew(p) {
      // console.log(p);
      this.snack.bar = true;
      this.loading = true;
      let self = this;
      Restful.cms.pages
        .create(p.data)
        .then((response) => {
          console.log(response);
          if (response.status === 201) {
            this.snack.color = "success";
            this.snack.text = response.data;
            this.list();
          }
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
          console.log(error.response.data.message);
          self.loading = false;
        });
      this.newDialog = p.state;
    },
    closeView(p) {
      this.$set(this.viewDialog, p.id, p.state);
    },
    closeRemove(p) {
      this.$set(this.removeDialog, p.id, p.state);
    },
    submitRemove(p) {
      console.log(p.id);
      this.snack.bar = true;
      this.loading = true;
      let self = this;
      Restful.cms.pages
        .remove(p.id)
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            this.snack.color = "success";
            this.snack.text = response.data;
            this.list();
          }
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
          console.log(error.response.data.message);
          self.loading = false;
        });
      this.$set(this.removeDialog, p.id, p.state);
    },
    closeEdit(p) {
      this.$set(this.editDialog, p.id, p.state);
    },
    submitEdit(p) {
      console.log(p);
      this.snack.bar = true;
      this.loading = true;
      let self = this;
      Restful.cms.pages
        .update(p.id, p.data)
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            this.snack.color = "success";
            this.snack.text = response.data;
            this.list();
          }
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
          console.log(error.response.data.message);
          self.loading = false;
        });

      this.$set(this.editDialog, p.id, p.state);
    },
  },
};
</script>

<style scoped></style>
